import { axios } from "../connection/ConnectionHandler";
import {
  APP_COOKIE_NAME,
  AUTH,
  PASS_COOKIE_NAME,
  SET_CURRENT_ROUTE,
  SET_PROFILE,
  USER_COOKIE_NAME,
  USER_ID_COOKIE_NAME,
} from "../constants";
import { API } from "../constants/api";
import { routes } from "../constants/routes";
import { deleteCookie, getCookie, setCookie } from "../utils";
import { fetchActivitys } from "./activitysActions";
import { fetchAditionalInformations } from "./aditionalInformationsActions";
import { fetchAlerts } from "./alertsActions";
import { fetchBusinessAlerts } from "./businessAlertsActions";
import { fetchBusinesss } from "./businesssActions";
import { fetchColumns } from "./columnsActions";
import { fetchConfigurations } from "./configurationsActions";
import { fetchConsultInvoicePlannings } from "./consultInvoicePlanningsActions";
import { fetchConsultInvoices } from "./consultInvoicesActions";
import { fetchConsultors } from "./consultorsActions";
import { fetchContainers } from "./containersActions";
import { fetchEquipments } from "./equipmentsActions";
import { fetchEstablishmentInfo } from "./establishmentInfoActions";
import { fetchEstablishments } from "./establishmentsActions";
import { fetchFees } from "./feesActions";
import { fetchHours } from "./hoursActions";
import { fetchIvas } from "./ivasActions";
import { fetchLabs } from "./labsActions";
import { fetchLeave } from "./leavesActions";
import { fetchLegislations } from "./legislationsActions";
import { fetchLocations } from "./locationsActions";
import { fetchLogsAcces } from "./logsAccesActions";
import { fetchMaintenances } from "./maintenancesActions";
import { fetchMatrixs } from "./matrixsActions";
import { fetchOtherAditionalInformations } from "./otherAditionalInformationsActions";
import { fetchParameters } from "./parametersActions";
import { fetchPlannings } from "./planningsActions";
import { fetchProjects } from "./projectsActions";
import { fetchRoles } from "./rolesActions";
import { fetchSamplingpoints } from "./samplingpointsActions";
import { fetchSamplings } from "./samplingsActions";
import { fetchServices } from "./servicesActions";
import { fetchSingUps } from "./singUpActions";
import { fetchSocietys } from "./societysActions";
import { fetchStocks } from "./stocksActions";
import { fetchSuggestions } from "./suggestionsActions";
import { fetchTimeRequests } from "./timeRequestActions";
import { fetchUnitys } from "./unitysActions";
import { fetchUsers } from "./usersActions";

const rememberLogin = (username, password, remember) => {
  if (remember) {
    setCookie(USER_COOKIE_NAME, username, 30);
    setCookie(PASS_COOKIE_NAME, password, 30);
  } else {
    deleteCookie(USER_COOKIE_NAME, "");
    deleteCookie(PASS_COOKIE_NAME, "");
  }
};

/* PERSISTENCY CHECK */
export const checkToken = () => {
  return async (dispatch) => {
    const token = await getCookie(APP_COOKIE_NAME);
    const userId = await getCookie(USER_ID_COOKIE_NAME);
    if (token) {
      dispatch({ type: AUTH, payload: { auth: true, token, userId } });
    }
  };
};

export const fetchUserProfile = () => {
  return (dispatch) => {
    axios
      .get(API.users.profile)
      .then((response) =>
        dispatch({ type: SET_PROFILE, payload: response.data })
      )
      .catch((err) => err);
  };
};

/* INITAL DATA LOAD */
export const loadInitialData = () => {
  return async (dispatch) => {
    await dispatch(fetchEstablishments());
    dispatch(fetchLogsAcces());
    dispatch(fetchOtherAditionalInformations());
    dispatch(fetchAditionalInformations());
    dispatch(fetchIvas());
    await dispatch(fetchRoles());
    dispatch(fetchConsultInvoices());
    dispatch(fetchConsultInvoicePlannings());
    dispatch(fetchConsultors());
    dispatch(fetchLabs());
    await dispatch(fetchSocietys());
    dispatch(fetchMaintenances());
    dispatch(fetchEquipments());
    dispatch(fetchStocks());
    dispatch(fetchBusinessAlerts());
    await dispatch(fetchColumns());
    await dispatch(fetchSamplings());
    dispatch(fetchFees());
    await dispatch(fetchMatrixs());
    await dispatch(fetchBusinesss());
    await dispatch(fetchUsers());
    await dispatch(fetchServices());
    dispatch(fetchContainers());
    dispatch(fetchLegislations());
    await dispatch(fetchUnitys());
    dispatch(fetchSuggestions());
    dispatch(fetchConfigurations());
    dispatch(fetchHours());
    dispatch(fetchActivitys());
    dispatch(fetchProjects());
    dispatch(fetchAlerts());
    dispatch(fetchSingUps())
    await dispatch(fetchEstablishmentInfo())
    dispatch(fetchLeave());
    dispatch(fetchTimeRequests());
    await dispatch(fetchLocations());
    dispatch(fetchPlannings());
    await dispatch(fetchSamplingpoints());
    await dispatch(fetchParameters());
  };
};

export const login = (username, password, remember) => {
  return async (dispatch) => {
    const response = await axios
      .post(API.auth.login, { username, password })
      .then((response) => {
        if (response && response.data) {
          const { access_token } = response.data || {};
          rememberLogin(username, password, remember);
          dispatch({
            type: AUTH,
            payload: { auth: true, token: access_token },
          });
          dispatch({ type: SET_PROFILE, payload: response.data.resUser });
          dispatch({
            type: SET_CURRENT_ROUTE,
            payload: routes["home"],
          });
        }
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const check2FA = (data) => {
  return async (dispatch) => {
    const response = await axios
      .post(API.auth.check2FA, data)
      .then((response) => {
        if (response && response.data) {
          const { access_token } = response.data || {};
          rememberLogin(data.username, data.password, data.remember);
          dispatch({
            type: AUTH,
            payload: { auth: true, token: access_token },
          });
          dispatch({ type: SET_PROFILE, payload: response.data.resUser });
          dispatch({
            type: SET_CURRENT_ROUTE,
            payload: routes["home"],
          });
        }
        return response;
      })
      .catch((err) => err);
    return response;
  };
};
